<template>
  <div class="gry-bg py-5">

  </div>
</template>
<script>
import { login } from '@/api/login'
import { setToken, setUserType  } from '@/utils/auth'
export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  mounted() {
    console.log("this.$route.query.token")
    console.log(this.$route.query.token)
    setToken(this.$route.query.token)
    let attribute = this.$route.query.attribute
    let userType = attribute == 1 ? 'custom' : 'seller'
    setUserType(userType)
    let routeName = attribute == 1 ? 'dashboard' : 'sellerDashboard'
    setTimeout(() => {
      this.$store.state.isLogin = true
      this.$router.push({
        name: routeName
      })
    }, 200)
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    doLogin() {
      if (!this.form.email) {
        this.$toast(this.$t('qing-shu-ru-you-xiang'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.$t('qing-shu-ru-mi-ma'))
        return
      }
      let form = new FormData()
      form.append('Email', this.form.email)
      form.append('Password', this.form.password)
      login(form).then(res => {
        if (res.code == 0) {
          setToken(res.data.token)
          let userType = res.data.attribute == 1 ? 'custom' : 'seller'
          setUserType(userType)
          let routeName = res.data.attribute == 1 ? 'dashboard' : 'sellerDashboard'
          setTimeout(() => {
            this.$store.state.isLogin = true
            this.$router.push({
              name: routeName
            })
          }, 200)
        } else {
          this.$toast.fail(res.msg)
        }
      })
      
    }
  }
}
</script>